@import '~desktop';
@import '../../Theme/colors.less';

//desktop view 
    .AppHeader{
        display: flex;
        justify-content: center;
        background-color: @background__primary;
        width:100%;
        position: fixed;
        z-index: 10;
        border-bottom:1px solid @grey__border;

        &__Contents {
            margin-left:150px;
            margin-right:150px;
            align-items: center;
            background-color: @background__primary;
            display: flex;
            height: 64px;
            width:100%;
            // min-width:1024px;
            // min-width:960px;
            justify-content: space-between;
         &__Left {
             display: flex;
             flex-direction: row;
             align-items: center;
             
             span {
                 color : @branding__primary;
                 font-family: "Shopee_font Bold";
                 font-size: 22px;
                 line-height: 24px;
                 padding-left:6px;
                 border-left: 1px solid #C4C4C4;
                }


         }  
         
         &__RightBtn {
            font-size: 16px;
            line-height: 16px;
            text-align: center;                        
            color: @branding__primary;
            border: 1px solid @branding__primary;
            border-radius: 20px;
            padding : 12px 25px;
            background-color: @background__primary;
            cursor: pointer;
         }

        &__shopeeLogo {
            height: 36px;
            width: 181px;
            margin-right:12px;
        }

        nav {
            align-items: center;
            display: flex;
            flex-direction: row;
        }


        &__styledLink {
            display: inline-block;
            color: #202325;
            font-size: 14px;
            margin-right: 60px;
            width:fit-content;
            line-height:24px;
            cursor: pointer;
            white-space:nowrap;

            span {
                 &:hover {
                color:@branding__primary;
            }
            }          
            &__Clicked {
                display: inline-block;
                color: @branding__primary;
                font-size: 14px;
                margin-right: 60px;
                cursor: pointer;
                width:fit-content;
                white-space:nowrap;

                span {
                    color:@branding__primary;
                    &:hover {
                   color:@branding__primary;
               }
               }
            }
        }
        &__LanguageToggle {
            margin-left : 40px;
            display: flex;
            align-items: center;
        }

        &__headerButton {
            background-color: @branding__primary;
            border: none;
            border-radius: 24px;
            font-size: 1rem;
            height: 40px;
            padding-left: 20px;
            padding-right: 20px;
            cursor: pointer;
            &__Text {
                color: @text__white;
                white-space: nowrap;
           }
        }


        &__itemNav {
            text-decoration: none;
        }


        &__itemTextNav {
            color: rgb(32, 35, 37);
        }

    }

}
@media (max-width: 1400px) {
    .AppHeader{
        display: flex;
        justify-content: center;
        background-color: @background__primary;
        width:100%;
        position: fixed;
        z-index: 10;
        border-bottom:1px solid @grey__border;

        &__Contents {
            // margin-left:150px;
            // margin-right:150px;
            align-items: center;
            background-color: #ffffff;
            display: flex;
            height: 64px;
            // width:100%;
            // min-width:1024px;
            min-width:960px;
            justify-content: space-between;

        &__shopeeLogo {
            height: 36px;
            width: 181px;
            margin-top: 2px;
        }

        nav {
            align-items: center;
            display: flex;
            flex-direction: row;
            // grid-area: nav;
            // justify-content: center;
        }

        &__styledLink {
            display: inline-block;
            color: #202325;
            font-size: 14px;
            margin-right: 30px;
            width:fit-content;
            line-height:24px;
            cursor: pointer;
            white-space:nowrap;

            span {
                 &:hover {
                color:@branding__primary;
            }
            }          
            &__Clicked {
                display: inline-block;
                color: @branding__primary;
                font-size: 14px;
                margin-right: 30px;
                cursor: pointer;
                width:fit-content;
                white-space:nowrap;

                span {
                    color:@branding__primary;
                    &:hover {
                   color:@branding__primary;
               }
               }
            }
        }
        &__LanguageToggle {
            margin-left : 30px;
            display: flex;
            align-items: center;
        }

        &__headerButton {
            background-color: @branding__primary;
            border: none;
            border-radius: 24px;
            font-size: 1rem;
            height: 40px;
            padding-left: 20px;
            padding-right: 20px;
            cursor: pointer;
            &__Text {
                color: @text__white;
                white-space: nowrap;
           }
        }


        &__itemNav {
            text-decoration: none;
        }


        &__itemTextNav {
            color: rgb(32, 35, 37);
        }

    }

}

}

//mobile view
.AppHeaderMobile {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
     align-items: center;
    border-bottom: #eeeff2;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    left: 0%;
    right: 0%;
    top: 0px;
    z-index: 99;
    height: 48px;

    &__shopeeLogo{
        left: 18px;
        position: absolute;
        top: 11px;
    }

    &__menuBackground {
        background: rgba(0, 0, 0, .5);
        z-index: 98;
        position: absolute;
        padding: 0;
        margin: 0;
        left: 0;
        width: 100%;
        height: 3000px;
        top: 48px;
        overflow-x: hidden
    }
    
    & nav {
        align-items: center;
        display: flex;
        grid-area: nav;
        grid-template-columns: 7, auto;
        justify-content: center;
    }

   
    &__styledLink {
        color: #202325 !important;
        cursor: pointer !important;

        &:hover {
            color:@branding__primary !important;
        }
        &__Clicked {
            color: @branding__primary !important;
            cursor: pointer !important;
            background: #FFF4F4;
            border-radius: 6px;
        }
    }

    &__LanguageToggle {
        margin-left: 24px;
        margin-top:36px;

    }

    &__downloadBtn {
        margin-top: 36px;
        margin-left: 24px;
        width: 181px;
        height: 40px;
        background: @branding__primary;
        border-radius: 30px;
        font-size: 16px;
        text-align: center;
        align-items: center;
        display: grid;
        &__Text {
            color: @text__white !important;
        }
    }
}


#sidebarMenu {
    height: 3000px;
    position: absolute;
    right: 0px;
    width: 250px;
    margin-top: 24px;
    transform: translateX(250px);
    transition: transform 250ms ease-in-out;
    background: #fafafa;
    z-index: 100;
}

.sidebarMenuInner {
    margin: 0;
    padding: 12px;
    position: relative;
}

.sidebarMenuInner :hover {
    background: #fff4f4;
    border-radius: 6px
}
.sidebarMenuInner li {
    list-style: none;
    color: rgb(0, 0, 0);
    padding: 12px;
    cursor: pointer // font:openSansRegular;
}

.sidebarMenuInner li a {
    font-size: 14px;
    line-height: 24px;
    color: @branding__primary
}

input[type=checkbox]:checked~#sidebarMenu {
    transform: translateX(0)
}

input[type=checkbox] {
    transition: all .3s;
    box-sizing: border-box;
    display: none
}


.sidebarIconToggle {
    transition: all .3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 18px;
    right: 18px;
    height: 16px;
    width: 20px;
}

.spinner {
    transition: all .3s;
    box-sizing: border-box;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #000
}


.horizontal {
    transition: all .3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px
}

.diagonal.part-1 {
    position: relative;
    transition: all .3s;
    box-sizing: border-box;
    float: left
}

.diagonal.part-2 {
    transition: all .3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px
}

input[type=checkbox]:checked~.sidebarIconToggle>.horizontal {
    transition: all .3s;
    box-sizing: border-box;
    opacity: 0
}

input[type=checkbox]:checked~.sidebarIconToggle>.diagonal.part-1 {
    transition: all .3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 7px
}

input[type=checkbox]:checked~.sidebarIconToggle>.diagonal.part-2 {
    transition: all .3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -7px
}




@primary-color: rgb(238, 77, 45);